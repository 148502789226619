<script setup>
import { defineProps } from "vue"

import MemberLevelTag from "@/components-uhas/members/MemberLevelTag.vue"

require('dotenv').config()
const VUE_APP_NO_IMAGE = process.env.VUE_APP_NO_IMAGE

const props = defineProps(['member'])

</script>

<template>
	<div id="container">
		<Avatar :image="props.member.avatar || VUE_APP_NO_IMAGE" size="large" shape="circle" />
		<span class="ml-1">{{ (props.member.first_name || '') + ' ' + (props.member.last_name || '') }}</span>
		<span class="ml-2" />
		<MemberLevelTag :level="props.member.commission_the_month_all_provider" />
	</div>
</template>

<style lang="scss" scoped>

#container {
	display: flex;
	align-items: center;
}

</style>
