<script setup>

import { reactive, ref } from "vue"
import { useRoute } from 'vue-router'
import { useAuthStore } from "@/store/auth"
import { useMemberStore } from "@/store/member"
import { useTagStore } from "@/store/tag"
import { useToast } from "primevue/usetoast"

import MemberAvatarNameWithTag from "@/components-uhas/members/MemberAvatarNameWithTag.vue"
import MemberChipAvatarName from "@/components-uhas/members/MemberChipAvatarName.vue"
import MemberPhone from "../../../components-uhas/members/MemberPhone.vue"

import provinces from "@/../../configs/location_th/provinces"

const authStore = useAuthStore()
const memberStore = useMemberStore()
const route = useRoute()
const tagStore = useTagStore()
const toast = useToast()

const filter = ref(null)
const loading = ref(true)
const member = ref(null)
const members = ref([])
const province = reactive({
	data: [],
	loading: true,
	selected: null,
	statics: provinces,
})
const tag = reactive({
	data: [],
	loading: true,
	selected: null,
})
const totalRecords = ref(0)

const commentDialogVisible = ref(false)

const clearFilter = () => {
  filter.value = null
  search()
}

const getCountProvinces = () => {
	memberStore.countProvinces().then((res) => {
		province.data = res.data.provinces.map((_province) => ({
			id: _province.province_id,
			name: province.statics.find((p) => p.id == _province.province_id).name,
			total: _province.total,
		}))
		province.loading = false
	})
}

const openCommentDialog = (data) => {
  commentDialogVisible.value = true
  member.value = data
}

const processComments = () => {
  members.value.forEach((member) => {
    const memberUserComments = []
    member.member_user_comments.forEach((comment) => {
      const index = memberUserComments.findIndex((m) => m.user_id === comment.user_id)
      if (index !== -1) {
        memberUserComments[index] = {
          avatar_url: comment.user.avatar_url,
          comment: comment.comment,
          id: comment.id,
          updated_at: comment.updated_at,
          user_id: comment.user_id,
        }
      } else {
        memberUserComments.push({
          avatar_url: comment.user.avatar_url,
          comment: comment.comment,
          id: comment.id,
          updated_at: comment.updated_at,
          user_id: comment.user_id,
        })
      }

      memberUserComments.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))

      member.memberUserComments = memberUserComments
    })
  })
}

const search = (e = {}) => {
  loading.value = true

  const page = (e.page || 0) + 1
  memberStore.index({
		filter: filter.value,
		page,
		province_id: province.selected ? province.selected.id : null,
		tags: tag.selected ? tag.selected.map((_tag) => _tag.id) : null,
	}).then((res) => {
    members.value = res.data.members.data
    totalRecords.value = res.data.members.total

    processComments()

    loading.value = false
  })
}

const tagInitial = () => {
	tagStore.indexAll().then((res) => {
		tag.data = res.data.tags.map((_tag) => ({ id: _tag.id, name: _tag.name }))
		tag.loading = false

		if (route.query.tag) {
			tag.selected = tag.data.filter((_tag) => _tag.id == route.query.tag)
		}
	}).finally(() => {
		search()
	})
}

const updateCommentDialog = () => {
  loading.value = true

  memberStore.update(member.value.id, { comment: member.value.comment }).then((data) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      return
    }

    const index = members.value.findIndex((m) => m.id === data.data.member.id)
    members.value[index] = data.data.member

    processComments()

    toast.add({severity:'success', summary: 'Success', detail: data.message, life: 3000})
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  }).finally(() => {
    commentDialogVisible.value = false
    loading.value = false
  })
}

tagInitial()
getCountProvinces()

</script>

<template>
  <Dialog v-model:visible="commentDialogVisible" modal header="Edit" :style="{ width: '50vw' }">
    <label class="block text-900 font-medium mb-2" for="comment">Comment</label>
    <InputText v-model="member.comment" id="comment" type="text" class="w-full mb-3" />

    <br/>

    <Button label="Save" class="w-full" :loading="loading" @click="updateCommentDialog" />
  </Dialog>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Members</h5>
        <DataTable
          class="p-datatable-gridlines"
          dataKey="id"
          filterDisplay="row"
          :lazy="true"
          :loading="loading"
          :paginator="true"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
          responsiveLayout="scroll"
          :rowHover="true"
          :rows="50"
          :totalRecords="totalRecords"
          :value="members"
          @page="search($event)"
        >

          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter" />
              <div class="col-12 md:col-6">
                <div class="p-inputgroup">
                  <InputText v-model="filter" placeholder="UID, Uhas UID, Name, Phone" />

									<Dropdown
										v-model="province.selected"
										:loading="province.loading"
										:options="province.data"
										placeholder="Province"
									>
										<template #value="slotProps">
											<div v-if="slotProps.value">
												{{ slotProps.value.name }}
											</div>
											<span v-else>
												{{ slotProps.placeholder }}
											</span>
										</template>
										<template #option="slotProps">
											<div class="flex align-items-center">
												<div>({{ slotProps.option.total }}) {{ slotProps.option.name }}</div>
											</div>
										</template>
									</Dropdown>

									<MultiSelect
										v-model="tag.selected"
										filter
										:loading="tag.loading"
										optionLabel="name"
										:options="tag.data"
										placeholder="Tags"
									/>
                  <Button icon="pi pi-search" class="p-button-warning" @click="search" />
                </div>
              </div>
            </div>
          </template>
          <template #empty>
              No records.
          </template>
          <template #loading>
              Loading. Please wait.
          </template>

          <Column field="uhas_uid" header="Uhas UID" />

          <Column field="name" header="Name">
            <template #body="{data}">
              <MemberAvatarNameWithTag :member="data" />
            </template>
          </Column>

          <Column field="phone" header="Phone">
            <template #body="{data}">
              <MemberPhone :phone="data.phone" />
            </template>
          </Column>

          <Column field="comment" header="Comment" style="min-width:20rem">
            <template #body="{data}">
              <div v-if="data.comment">
                <small>ล่าสุด: {{ data.comment }}</small>
              </div>
              <div class="flex">
                <div v-for="comment in data.memberUserComments" :key="comment.id" class="p-2 m-2">
									<MemberChipAvatarName :avatar="comment.avatar_url" :name="comment.comment" />

                  <div><small style="color:red">
                    {{ new Date(comment.updated_at).toISOString().slice(0, 10) }}
                  </small></div>
                </div>
              </div>
            </template>
          </Column>

          <Column header="Options">
            <template #body="{data}">
              <router-link  target="_blank" :to="`/members/${data.id}`">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-info mr-2 mb-2" />
              </router-link>

              <Button icon="pi pi-comment" class="p-button-rounded p-button-warning mr-2 mb-2" @click="openCommentDialog(data)"/>

              <span v-for="account in data.accounts" :key="account.id">
								<router-link
                  v-if="account.type == 'axi'"

                  :class="{'cursor-not-allowed': !authStore.hasPermission('axis')}"
                  :to="
                    authStore.hasPermission('axis')
                    ? `/axis/${account.reference_id}`
                    : ''
                  "
                >
                  <img alt="logo" src="/brokers/axi.png" height="32" class="mr-2 mb-1" />
                </router-link>

                <router-link
                  v-if="account.type == 'exness'"

                  :class="{'cursor-not-allowed': !authStore.hasPermission('exnesses')}"
                  :to="
                    authStore.hasPermission('exnesses')
                    ? `/exnesses/${account.reference_id}`
                    : ''
                  "
                >
                  <img alt="logo" src="/brokers/exness.png" height="32" class="mr-2 mb-1" />
                </router-link>

								<router-link v-else-if="
                  account.type == 'fxgt'"

                  :class="{'cursor-not-allowed': !authStore.hasPermission('fxgts')}"
                  :to="
                    authStore.hasPermission('fxgts')
                    ? `/fxgts/${account.reference_id}`
                    : ''
                  "
                >
                  <img alt="logo" src="/brokers/fxgt.png" height="32" class="mr-2 mb-1" />
                </router-link>

                <router-link v-else-if="
                  account.type == 'fbs'"

                  :class="{'cursor-not-allowed': !authStore.hasPermission('fbss')}"
                  :to="
                    authStore.hasPermission('fbss')
                    ? `/fbss/${account.reference_id}`
                    : ''
                  "
                >
                  <img alt="logo" src="/brokers/fbs.png" height="32" class="mr-2 mb-1" />
                </router-link>

                <router-link v-else-if="
                  account.type == 'hfm'"

                  :class="{'cursor-not-allowed': !authStore.hasPermission('hfms')}"
                  :to="
                    authStore.hasPermission('hfms')
                    ? `/hfms/${account.reference_id}`
                    : ''
                  "
                >
                  <img alt="logo" src="/brokers/hfm.png" height="32" class="mr-2 mb-1" />
                </router-link>

                <router-link v-else-if="
                  account.type == 'xm'"

                  :class="{'cursor-not-allowed': !authStore.hasPermission('xms')}"
                  :to="
                    authStore.hasPermission('xms')
                    ? `/xms/${account.reference_id}`
                    : ''
                  "
                >
                  <img alt="logo" src="/brokers/xm.png" height="32" class="mr-2 mb-1" />
                </router-link>
              </span>
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .cursor-not-allowed {
    cursor: not-allowed;
  }
</style>
