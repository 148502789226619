<script setup>
import { defineProps } from "vue"

require('dotenv').config()
const VUE_APP_NO_IMAGE = process.env.VUE_APP_NO_IMAGE

const props = defineProps(['avatar', 'name'])

</script>

<template>
  <Chip
    :label="props.name || '-'"
    :image="props.avatar || VUE_APP_NO_IMAGE"
  />
</template>
