import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useTagStore = defineStore('tag', {
  actions: {
    destroy: (id) => axios.delete(`${VUE_APP_ENDPOINT_URL}/api/admin/tags/${id}`),
		index: ({ filter, page = 1 }) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/tags?page=${page}${ filter ? '&filter=' + filter : '' }`),
		indexAll: () => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/tags`),
    store: (data) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/tags`, data),
		update: (id, data) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/tags/${id}`, data),
  }
})
