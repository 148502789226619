import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useMemberStore = defineStore('member', {
  actions: {
		countProvinces: () => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/members/count-provinces`),
		courseExpire: (id, { course_id, expire_date }) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/members/${id}/course-expire`, { course_id, expire_date }),
		index: ({ filter, page = 1, province_id = null, tags = null }) => {
			const query = [`page=${page}`]
			if (filter) query.push(`filter=${filter}`)
			if (province_id) query.push(`province_id=${province_id}`)
			if (tags) query.push(`tags=${tags}`)

			return axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/members?${query.join('&')}`)
		},
		indexMemberLevel: ({ page = 1, option }) => {
			option = option ?? {}
			const query = [`page=${page}`]
			if (typeof option.date === 'object') {
				const date = new Date(option.date)
				const year = date.getFullYear()
				let month = date.getMonth() + 1
				month = month < 10 ? `0${month}` : month
				query.push(`year=${year}`)
				query.push(`month=${month}`)
			}
			if (typeof option.level === 'string') query.push(`level=${option.level}`)

			return axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/members-levels?${query.join('&')}`)
		},
		sendMessage: (id, message) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/members/${id}/send-message`, { message }),
		show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/members/${id}`),
		update: (id, member) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/members/${id}`, member),
		updateTags: (id, tags) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/members/${id}/tags`, { tags }),
  }
})
